import React, { useEffect, useMemo } from 'react';
import { CalendarResource, Job, JobAttributeValue } from 'types';
import { useDrag } from 'react-dnd'
import { getEmptyImage } from 'react-dnd-html5-backend'
import { isMobile } from 'react-device-detect';
import JobStatuses from 'components/JobStatuses';
import JobAttributes from 'components/JobAttributes';
import { Card, CardBody, CardTitle, JobAddress, JobMaterialStatus, JobNumber, Subdivision } from './styled';
import { displayJobAttribute } from 'utils/jobUtils';
import { openJobDetail } from 'contexts/CalendarContext/reducer';
import { useCanAdminOnlyJobAttributes } from 'config/auth';

interface JobCardProps {
  job: Job;
  calendarResource?: CalendarResource;
  selectedJobId?: number;
  dispatch?: React.Dispatch<any>;
  viewMode?: string;
  canDragJob?: boolean;
  onDragStart?: () => void;
  onDragEnd?: (item: any, monitor: any) => void;
}

function JobCard({ job, calendarResource, selectedJobId, dispatch, canDragJob, onDragStart, onDragEnd, ...props }: JobCardProps) {
  const adminOnlyJobAttributes = useCanAdminOnlyJobAttributes();
  const isSelected = job.id === selectedJobId;
  const isUnassigned = !calendarResource;

  // Filter attribute values to only those with displayOnCard true.
  const displayedAttributes = useMemo(() => {
    return job.jobAttributeValues?.filter((jobAttributeValue: JobAttributeValue) =>
      displayJobAttribute(jobAttributeValue, adminOnlyJobAttributes)
    );
  }, [job.jobAttributeValues, adminOnlyJobAttributes]);

  const [, dragRef, dragPreviewRef] = useDrag(
    () => ({
      type: 'Job',
      item: () => {
        onDragStart && onDragStart();
        return { ...job, calendarResource };
      },
      canDrag: canDragJob && !isMobile,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => onDragEnd && onDragEnd(item, monitor)
    }),
    []
  )

  useEffect(() => {
    dragPreviewRef(getEmptyImage(), { captureDraggingState: true })
  }, [dragPreviewRef])

  return (
    <Card
      ref={dragRef}
      onClick={() => dispatch && dispatch(openJobDetail(job))}
      $unassigned={isUnassigned}
      $resourceColor={calendarResource?.displayColor}
      {...props}
    >
      <CardBody $selected={isSelected}>
        <CardTitle>
          <div>
            <JobAddress>{job.propertyAddress?.address}</JobAddress>
            {job.propertyAddress?.subdivision && <Subdivision>{job.propertyAddress?.subdivision?.name}</Subdivision>}
          </div>
          <JobMaterialStatus job={job} />
        </CardTitle>
        {job.jobStatuses && job.jobStatuses.length > 0 && <JobStatuses job={job} />}
        {job.propertyAddress?.jobNumber && (
          <JobNumber>Job# {job.propertyAddress.jobNumber}</JobNumber>
        )}
        {job.propertyAddress?.plan && (
          <JobNumber>Plan {job.propertyAddress.plan}</JobNumber>
        )}
        {displayedAttributes && displayedAttributes.length > 0 && (
          <JobAttributes values={displayedAttributes} viewMode={props.viewMode} />
        )}
      </CardBody>
    </Card>
  );
}

export default React.memo(JobCard);
