import { useCookies } from 'react-cookie';
import jwtDecode  from 'jwt-decode';
import { Permission } from 'types';
import { luxonDT } from 'utils/dates';

enum ResourceAction {
  CREATE = 'CREATE',
  EDIT = 'EDIT',
  VIEW = 'VIEW',
  DELETE = 'DELETE',
}

const ADMIN_ACCESS_PERMISSION = 'ADMIN_ACCESS';
const PERMIT_ALL_CALENDAR_RESOURCES = 'PERMIT_ALL_CALENDAR_RESOURCES';
const ALL_RESOURCES_EVENT_EDIT = 'APP_ALL_RESOURCES_EVENT_EDIT';
const APP_JOB_EDIT_ONLY_JOB_ATTRIBUTES = 'APP_JOB_EDIT_ONLY_JOB_ATTRIBUTES';
const APP_JOB_ADMIN_ONLY_JOB_ATTRIBUTES = 'APP_JOB_ADMIN_ONLY_JOB_ATTRIBUTES';
const APP_EDIT_PAST_JOBS = 'APP_EDIT_PAST_JOBS';
const TOKEN_NAME = process.env.REACT_APP_AUTH_TOKEN_COOKIE_NAME || 'cfc.auth_token';

function resourceActionPermissionList(resourceName: string, actionName: ResourceAction, includeApp: boolean = true): string[] {
  const permissions = [
    'SUPERADMIN',
    'ADMIN_ALL',
    `ADMIN_${resourceName.toUpperCase()}_ALL`,
    `ADMIN_${resourceName.toUpperCase()}_${actionName.toUpperCase()}`,
  ];

  if (includeApp) {
    permissions.push(`APP_${resourceName.toUpperCase()}_ALL`);
    permissions.push(`APP_${resourceName.toUpperCase()}_${actionName.toUpperCase()}`);
  }

  return permissions;
}

const JOB_EDIT_PERMISSIONS = resourceActionPermissionList('Job', ResourceAction.EDIT);
const JOB_DELETE_PERMISSIONS = resourceActionPermissionList('Job', ResourceAction.DELETE);
const EVENT_EDIT_PERMISSIONS = resourceActionPermissionList('Event', ResourceAction.EDIT);
const EVENT_DELETE_PERMISSIONS = resourceActionPermissionList('Event', ResourceAction.DELETE);
const PROPERTY_ADDRESS_CREATE_PERMISSIONS = resourceActionPermissionList('PropertyAddress', ResourceAction.CREATE);
const VIEW_ALL_RESOURCES_PERMISSIONS = [
  ...resourceActionPermissionList('CalendarResource', ResourceAction.VIEW, false),
  PERMIT_ALL_CALENDAR_RESOURCES,
];

export interface UserToken {
  id: number;
  email?: string;
  firstName?: string;
  lastName?: string;
  activePermissions: [string];
  sub?: string;
  exp?: number;
  iat?: number;
}

// custom hook to handle authToken - we use composition to decouple the auth system and it's storage
export const useAuthToken = () => {
  // we use react-cookies to access our cookies
  const [cookies, setCookie, removeCookie] = useCookies([TOKEN_NAME]);

  // this function allows to save any string in our cookies, under the key "authToken"
  const setAuthToken = (authToken: string) => setCookie(TOKEN_NAME, authToken);

  // this function removes the key from our cookies. Useful to logout
  const removeAuthToken = () => removeCookie(TOKEN_NAME);

  return [cookies[TOKEN_NAME], setAuthToken, removeAuthToken];
};

export const useIsAuthenticated = (): boolean => {
  const authData = useAuthData();
  if (!authData) {
    return false;
  }

  // Verify that the jwt has not expired.
  const currentDate = new Date();

  // JWT exp is in seconds.
  return !authData.exp || authData.exp * 1000 >= currentDate.getTime();
}

export const useAuthData = (): UserToken | undefined => {
  const [authToken] = useAuthToken();

  if (!authToken) {
    return undefined;
  }

  return jwtDecode(authToken);
}

const useHasPermission = (permissions: string | string[]): boolean => {
  const authData = useAuthData();
  if (!authData) {
    return false;
  }

  if (Array.isArray(permissions)) {
    return permissions.some(p => authData.activePermissions.includes(p));
  } else {
    return authData.activePermissions.includes(permissions);
  }
}

export const useIsAdmin = (): boolean => {
  const authData = useAuthData();
  if (!authData) {
    return false;
  }

  return authData.activePermissions.includes(ADMIN_ACCESS_PERMISSION);
}

export const useCanViewAllResources = (): boolean => {
  return useHasPermission(VIEW_ALL_RESOURCES_PERMISSIONS);
}

export const useCanCreateJobs = (): boolean => {
  return useHasPermission(JOB_EDIT_PERMISSIONS);
}

export const useCanEditJobs = (startDate: string | undefined): boolean => {
  const canEditPastJobs = useHasPermission(APP_EDIT_PAST_JOBS);
  const canEditCurrentJobs = useHasPermission(JOB_EDIT_PERMISSIONS);

  return canEditJobsOnDate(startDate, canEditPastJobs, canEditCurrentJobs);
}

export const useCanEditJobInRange = (dates: Date[]) => {
  const canEditPastJobs = useHasPermission(APP_EDIT_PAST_JOBS);
  const canEditCurrentJobs = useHasPermission(JOB_EDIT_PERMISSIONS);

  return dates.reduce((canEdit, d) => {
    const dateString = luxonDT(d, true).toFormat('yyyy-MM-dd');
    canEdit[dateString] = canEditJobsOnDate(dateString, canEditPastJobs, canEditCurrentJobs);

    return canEdit;
  }, {} as Record<string, boolean>);
}

export const useCanDeleteJobs = (): boolean => {
  return useHasPermission(JOB_DELETE_PERMISSIONS);
}

export const useCanEditEvents = (): boolean => {
  return useHasPermission(EVENT_EDIT_PERMISSIONS);
}

export const useCanDeleteEvents = (): boolean => {
  return useHasPermission(EVENT_DELETE_PERMISSIONS);
}

export const useCanCreatePropertyAddresses = (): boolean => {
  return useHasPermission(PROPERTY_ADDRESS_CREATE_PERMISSIONS);
}

export const useCanEditAllResourcesEvent = (): boolean => {
  return useHasPermission(ALL_RESOURCES_EVENT_EDIT);
}

export const useCanEditOnlyJobAttributes = (): boolean => {
  return useHasPermission(APP_JOB_EDIT_ONLY_JOB_ATTRIBUTES);
}

export const useCanAdminOnlyJobAttributes = (): boolean => {
  return useHasPermission(APP_JOB_ADMIN_ONLY_JOB_ATTRIBUTES);
}

export const useCanEditJobAttribute = (restrictedPermission: Permission | undefined, editOnlyPermission: Permission | undefined): boolean =>  {
  let permissions = restrictedPermission ? ['SUPERADMIN', restrictedPermission.slug] : JOB_EDIT_PERMISSIONS;

  // If the User is limited to Only Job Attributes validate against invalid permission to force fail
  if (useCanEditOnlyJobAttributes()) {
    permissions = editOnlyPermission ? [editOnlyPermission.slug] : [''];
  }

  return useHasPermission(permissions);
}

function canEditJobsOnDate(startDate: string | undefined, canEditPastJobs: boolean, canEditCurrentJobs: boolean): boolean {
  if (canEditCurrentJobs && canEditPastJobs) {
    return true;
  } else if (canEditCurrentJobs) {
    // If there's no date to compare return true
    if (!startDate) {
      return true;
    }

    const startOfWeek = luxonDT().startOf('week').toJSDate();

    // Users can always edit jobs from the beginning of the week and into the future
    if (startDate >= startOfWeek.toISOString().slice(0, 10)) {
      return true;
    }

    // Weekly cutoff is 5pm Monday
    const weeklyCutoff = new Date(startOfWeek);
    weeklyCutoff.setHours(17);

    const currentTime = luxonDT().toJSDate();
    const beforeCutoff = currentTime <= weeklyCutoff;

    // If before cutoff then user can edit jobs from the previous week
    if (beforeCutoff) {
      const previousStartOfWeek = new Date(startOfWeek);
      previousStartOfWeek.setDate(startOfWeek.getDate() - 7);

      if (startDate >= previousStartOfWeek.toISOString()) {
        return true;
      }
    }
  }

  return false;
}
